import React from 'react';
import { graphql } from 'gatsby';
import Loadable from '@loadable/component';
import { Grid, Row, Col } from '@r007/react-styled-flexboxgrid';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import Segmented from '../components/Segmented';
import Button from '../components/Button';
import HeroBlock from '../components/HeroBlock';
import Heading from '../components/Heading';
import SolutionsBlock from '../components/SolutionsBlock';
import CoreTechnologiesBlock from '../components/CoreTechnologiesBlock';
import { Spacing, LogoBackgroundWrapper, DarkAccentRowWrapper, AccentRowWrapper} from '../components/common';
import mq from '../common/mq';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Blueybuoy from '../images/vessel-blueybuoy.svg';
import TrimaranVessel from '../images/vessel-trimaran.svg';
import BluebottleVessel from '../images/BethClassBluebottle-01.svg';
// Import bluebottle illustration
import Bluebottle from '../images/BathyClassBluebottle-01.svg';


// Lazy load all the components
const ArticlePreviewBlock = Loadable(() => import(`../components/ArticlePreviewBlock`));
const NewsletterForm = Loadable(() => import(`../components/NewsletterForm`));
const ContactUs = Loadable(() => import(`../components/ContactUs`));

const RadarImage = styled(GatsbyImage)`
  margin: 0 -2.5em -8em;
  z-index: 2;

  @media (max-width: ${mq.max[768]}) {
    margin: -10em -1em 0;
  }
`;

function IndexPage({ data }) {
  const breakpoints = useBreakpoint();
  
  return (
    <Layout>
      <SEO
        title="OCIUS - Satellites of the Sea"
        description="OCIUS Technology's Uncrewed Surface Vessels (USVs) provide innovative autonomous solutions for persistent maritime surveillance"
      />

      {data.HeroBackground && (
        <HeroBlock
          image={data.HeroBackground.childImageSharp.gatsbyImageData.images.fallback}
          scrim="dark"
          fallbackColor="#2079A8"
        >
          <Row>
            <Col xs={18} md={13} mdOffset={5} lg={13} lgOffset={5}>
              <Spacing $value="400px" />
              <Heading level={4} size="small" weight="thick" color="#297dad" text-align="right">
              </Heading>
            </Col>
          </Row>
        </HeroBlock>
      )}
      <DarkAccentRowWrapper>
        <Grid>
          <Row>
            <Col xs={12} md={8} lg={8} mdOffset={2}>
              <Spacing $value="50px" $mdValue="0px" $xsValue="0px" />
              <Heading level={3} color="black" size="large" weight="thick">
                Autonomous Maritime Domain Awareness
              </Heading>
              <Heading level={3} color="black" size="medium" weight="thick" underline="left">
                Intelligent, Networked, Integrated
              </Heading>
              <p className="black">
                Power large payloads, roam widely, and stay at sea for months at a time. Ocius USVs
                offer economic and operational advantages over conventional methods in a multitude
                of industries. Utilising solar, wind and wave power, these vessels can autonomously
                monitor designated areas for lengths of time. The videos and links below demonstrate
                just some of the solutions we offer.
              </p>

   
            </Col>
          </Row>
 <Grid className="page-content">
          <Row className="centered">
            <Col xs={12} md={8} lg={8} mdOffset={2}>
	<Row>
<iframe 
width="560" height="315" src="https://www.youtube.com/embed/OWYjdjgMoUc?si=_mB4PEMXiYAQJED7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"></iframe>

	</Row>
<Row>
<Spacing $value="20px" $mdValue="30px" $xsValue="0px" />
</Row>
<Row>
 <Button color="blue" size="tiny" href="/usv">
                Find out more
              </Button>
</Row>
   </Col>
          </Row>
        </Grid>
        </Grid>
        <Spacing $value="100px" $mdValue="50px" $xsValue="0px" />
      </DarkAccentRowWrapper>

      <AccentRowWrapper>
        <CoreTechnologiesBlock />
       </AccentRowWrapper>

      <LogoBackgroundWrapper position="left">
        <Segmented>
          <Grid className="page-content centered">
            <Row className="centered">
              <Col xs={12} md={8} lg={8} mdOffset={2}>
                <Spacing $value="80px" />
                <Heading level={2} size="large" weight="thick" underline="center">
                  Ocius Vessels
                </Heading>
              </Col>
            </Row>
            <Row className="primary-content left-align">
              <Col xs={12} md={6} lg={4}>
                <img src={BluebottleVessel}
                  width="367"
                  height="314"
                  alt="Bluebottle Class Vessel"
                />
                <Heading level={3} size="medium" weight="thick">
                 BETH Class
                </Heading>
                <ul>
                  <li>Sail folds down for zero windage and Tactical Range Advantage</li>
                  <li>Wave power plus solar + wind</li>
                  <li>Keel winch for ASW</li>
                  <li>Launch from a boatramp</li>
		 <li>30,000+ NM unescorted in Australia</li>
		 <li>AMSA approved</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <img
                  src={Bluebottle} width="566" height="581" alt="Bluebottle Ocius"/>
                <Heading level={3} size="medium" weight="thick">
                  BATHY Class
                </Heading>
                <ul>
                  <li>Diesel Hybrid</li>
                  <li>Transit Renewable Energy</li>
                  <li>30 days on station doing hydrography</li>
                  <li>Bathymetry to 600m</li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={4}>
                <img

src={TrimaranVessel} width="566" height="581" alt="Shipmate Class Vessel"  />
                <Heading level={3} size="medium" weight="thick">
                  Shipmate<sup>™</sup> Class
                </Heading>
                <ul>
               
		 <li>3 mode USV - patent registered</li>
                  <li>Loiter/stealth mode: solar and wave energy</li>
                  <li>Hull speed mode: 6-8 knots hybrid diesel</li>
		 <li>Foiling speed mode: 17 -40 knots hybrid diesel</li>
		 <li>Scalable 2.8m - 11m</li>
                </ul>
              </Col>
            </Row>
            <Button color="blue" size="tiny" href="/usv/">
              Find out more
            </Button>
          </Grid>
        </Segmented>
        
        <SolutionsBlock />

        { (!breakpoints.sm) ? (
        <Segmented>
          <Grid>
            <Row className="centered">
              <Col xs={12} md={8} lg={8} mdOffset={2}>
                <Spacing $value="80px" />
                <Heading level={2} size="large" weight="thick" underline="center">
                  Ocius news headlines
                </Heading>
              </Col>
            </Row>
            <ArticlePreviewBlock />
          </Grid>
        </Segmented>
        ) : null}
        
      </LogoBackgroundWrapper>

      <Segmented>
        <NewsletterForm />
      </Segmented>
      <Segmented>
        <ContactUs />
      </Segmented>
    </Layout>
  );
}

export const query = graphql`
  query getHomepageImages {
    HeroBackground: file(relativePath: { eq: "images/ocius-bg.jpg" }) {
      ...imageSharpHeroBackground
    }
    Computer: file(relativePath: { eq: "images/computer.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, width: 700, placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
  }

  fragment imageSharpHeroBackground on File {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
`;

export default IndexPage;
